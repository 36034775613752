<template>
<div class="main">
  <Header1></Header1>
  <Header></Header>
  <!-- banner图 -->
  <div class="banner" :style="{backgroundImage: 'url('+ getBgImgLink() +')',   height: getHeight()}">
    <div class="wrap" :style="{height: getHeight()}">
      <div class="word-wrapper">
        <span class="title">友情链接</span> <br>
        <!-- <span class="dec">小wu和他的朋友们</span> -->
      </div>
    </div>
  </div>
  <!-- content -->
  <div class="content">
    <!-- 欢迎小伙伴们交换友链 -->
    <div class="biaoti">欢迎小伙伴们交换友链</div> 
    <div class="links_info">
      <div class="item"> <span>在添加本站后将您的友链信息通过留言，或者QQ，微信，邮箱等方式告知我即可，核实后会立即添加，一起加油丫！</span> </div>
      <!-- <div class="item"> <span>一起变得更强</span> </div> -->
    </div>
    <!-- 我的信息 -->
    <div class="biaoti">本站信息</div> 
    <div class="mine_data">
      <div class="title item"> <span>站点名称：</span> <span>{{myData.websiteName}}</span> </div>
      <div class="desc item"> <span>站点描述：</span> <span>{{myData.description}}</span> </div>
      <div class="url item"> <span>网站地址：</span> <span class="color">{{myData.url}}</span> </div>
      <div class="avatar item"> <span>我的头像：</span> <span class="color">{{myData.avatar}}</span> </div>
    </div>
    <!-- 友链展示 -->
    <div class="biaoti">小伙伴们</div>
    <div class="item_wrapper">
      <div class="item" v-for="item in links" :key="item.index" v-bind:style="{borderLeft: '3px solid ' + getColor()}">
        <a :href="item.url" target="view_window">
          <div class="avatar"><img :src="item.avatar" alt=""></div>
          <div class="name" >{{item.websiteName}}</div>
          <div class="desc">{{item.description}}</div>
          <!-- <div class="url">{{item.url}}</div> -->
        </a>
      </div>
    </div> 
    <!-- 食用网站 -->
    <div class="biaoti">食用网站</div>
    <div class="item_wrapper">
      <div class="item" v-for="item in eatLinks" :key="item.index" v-bind:style="{borderLeft: '3px solid ' + getColor()}">
        <a :href="item.url" target="view_window">
          <div class="avatar"><img :src="item.avatar" alt=""></div>
          <div class="name" >{{item.websiteName}}</div>
          <div class="desc">{{item.description}}</div>
          <!-- <div class="url">{{item.url}}</div> -->
        </a>
      </div>
    </div> 
  </div>

  <!-- version -->
  <Version></Version>
  <el-backtop class="up"> <i class="el-icon-top"></i></el-backtop>
</div>
</template>


<script>
import Header1 from "../components/Header1.vue"  ;
import Header from "../components/Header.vue"  ;
import Version from "../components/Version.vue"  ;
export default {
  name : 'Link' , 
  components:{Header1,Header , Version} , 
  data(){
    return {
      collorArr:["#0089FA" , "#FFA900" , "#00CC00" , "#00CCFF" , "#404040" , "#FF002B"] ,
      myData:{} , 
      links:[] , // 友链列表
      eatLinks:[] , // 食用网站列表
      linkImg:""
    }
  } , 
  methods:{
    getHeight(){
      return window.innerHeight + 'px' ; 
    } , 
    // 获取设置的随机颜色
    getColor(){
      let num = Math.floor(Math.random()*this.collorArr.length);  
      return this.collorArr[num] ; 
    }  , 
    // 获取友链列表
    getLinksList(){
      this.$axios.get("/getLinksList" ).then(res => {
        this.links = res.data.data ; 
      }) ; 
    } , 
    // 获取食用网站列表
    getEatLinksList(){
      this.$axios.get("/getEatList" ).then(res => {
        this.eatLinks = res.data.data ; 
      }) ; 
    } , 
    // 获取小屋的站点信息
    getXiaowu(){
      this.$axios.get('/getlinks/' + 1 ).then(res => {
        this.myData = res.data.data ; 
      })
    } , 
    // 获取友链的背景图片
    getLinkImg(){
      const _this = this ; 
      _this.$axios.get("/getBgLink").then(res => {
        _this.linkImg = res.data.data ; 
        // return res.data.data ; 
      }) ; 
    }  , 
    getBgImgLink(){
      const _this = this ; 
      return _this.linkImg ; 
    }
  } , 
  created(){
    this.getLinksList() ; 
    this.getXiaowu() ; 
    this.getLinkImg() ; 
    this.getEatLinksList() ; 
  }
}
</script>


<style scoped>
.banner{
  width: 100vw;
  height: 100vh;
  /* background-image: url("../img/k-on1.jpeg"); */
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  /* position: absolute;
  left: 0;
  top: 0;
  overflow: hidden; */
  opacity: 1;
  margin-bottom: 5rem;
}
.banner .wrap{
  width: 100vw;
  height: 100vh;
  background-color: rgba( 0, 0, 0, 0.2);
  text-align: center;
  position: relative;
  display: flex;
  justify-content: center;
}
.banner .wrap .word-wrapper{
  position: absolute;
  top: 35%;
}
.banner .wrap .word-wrapper .title{
  color: white;
  font-size: 40px;
}
.banner .wrap .word-wrapper .dec{
  color: white;
  font-size: 15px;
}

@media all and (min-width:850px) {
  /* 主体区域 */
  .content{
    width: 59vw ; 
    /* background-color: pink; */
    /* border: 1px solid pink; */
    margin: 0 auto;
    /* margin-top: 4rem; */
    margin-bottom: 10rem;
    background: rgba(255, 255, 255, 0.8);
    padding-top: 1rem;
    padding-bottom: 10rem;
    border-radius: 5px;
  }
  .content .biaoti{
    height: 2.5rem;
    line-height: 2.5rem;
    padding-left: 1rem;
    font-size: 1.5rem;
    font-weight: 600;
    color: rgb(112, 112, 112);
    border-left: .4rem solid #333;
    margin-top: 6rem;
  }
  .content .links_info{
    margin: 1rem;
    border-radius: 5px;
    color: rgb(41, 107, 121);
    box-shadow: 1px 4px 15px rgb(125 147 178 / 32%);
    transition: all .3s;
    font-size: 1.4rem;
    background: rgba(255, 255, 255, 0.5);
  }
  .content .links_info:hover{
    box-shadow: 1px 4px 15px rgb(75 174 194 / 32%);
    color: rgb(75, 174, 194);
    cursor: pointer;
  }
  .content .links_info .item{
    padding: 2rem;
    /* font-size: 1.2rem; */
  }
  .content .mine_data{
    margin: 1rem;
    border-radius: 5px;
    box-shadow: 1px 4px 15px rgb(125 147 178 / 32%);
    transition: all .3s;
    background: rgba(255, 255, 255, 0.5);
  }
  .content .mine_data:hover{
    box-shadow: 1px 4px 15px rgb(75 174 194 / 32%);
  }
  .content .mine_data .item{
    padding: 1.5rem;
    font-size: 1.4rem;
  }
  .content .mine_data .color{
    color: #277840;
  }
  
  .content .item_wrapper{
    margin: 1rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: baseline;
    border-radius: 5px;
    box-shadow: 1px 4px 15px rgb(125 147 178 / 32%);
    transition: all .3s;
    background: rgba(255, 255, 255, 0.5);
  }
  .content .item_wrapper:hover{
    box-shadow: 1px 4px 15px rgb(80 90 110 / 32%);
  }
  .content .item_wrapper .item{
    width: 25.6rem;
    height: 11rem;
    border-radius: .5rem;
    background-color: rgba(255, 255, 255, 0.5);
    margin: 1rem 1rem;
    position: relative;
    /* box-shadow: 1px 4px 15px rgb(125 147 178 / 32%); */
    border: 1px solid rgb(235, 232, 232);
    box-sizing: border-box;
    transition: all .3s;
    border-left: 3px solid rgb(75,174,194);
  }
  .content .item_wrapper .item a{
    width: 100%;
    height: 100%;
    background-color: #fff;
  }
  .content .item_wrapper .item:hover{
    box-shadow: 1px 4px 15px rgb(75 174 194 / 32%);
    cursor: pointer;
  }
  .content .item_wrapper .item .avatar{
    margin: 1rem 0 0 1.5rem;
  }
  .content .item_wrapper .item .avatar img{
    background-color: rgb(231, 231, 231);
    width: 5rem;
    height: 5rem;
    display: block;
    border-radius: 50%;
    box-sizing: border-box;
    border: 1px solid rgb(153, 151, 151);
  }
  .content .item_wrapper .item .name{
    /* background-color: pink; */
    font-size: 1.4rem;
    position: absolute;
    left: 8rem;
    top: 3rem;
    color: rgb(41, 107, 121);
  }
  .content .item_wrapper .item .desc{
    font-size: 1.2rem;
    position: absolute;
    top: 7.2rem;
    left: 1.5rem;
    color: rgb(67, 139, 153);
  }
}

@media all and (max-width:850px) {
  /* 主体区域 */
  .content{
    width: 96vw ; 
    /* background-color: pink; */
    /* border: 1px solid pink; */
    margin: 0 auto;
    /* margin-top: 4rem; */
    margin-bottom: 8rem;
    background-color: rgba(255, 255, 255, 0.5);
    padding-top: 1px;
    padding-bottom: 5rem;
    border-radius: 5px;
  }
  .content .biaoti{
    height: 2.5rem;
    line-height: 2.5rem;
    padding-left: 1rem;
    font-size: 1.4rem;
    font-weight: 600;
    color: rgb(112, 112, 112);
    border-left: .4rem solid #333;
    margin-top: 6rem;
  }
  .content .links_info{
    margin: 1rem;
    border-radius: 5px;
    color: rgb(41, 107, 121);
    box-shadow: 1px 4px 15px rgb(125 147 178 / 32%);
    transition: all .3s;
    background: rgba(255, 255, 255, 0.7);
  }
  .content .links_info:hover{
    box-shadow: 1px 4px 15px rgb(80 90 110 / 32%);
    color: rgb(235, 100, 123);
    cursor: pointer;
  }
  .content .links_info .item{
    padding: 2rem;
    font-size: 1.2rem;
  }
  .content .mine_data{
    margin: 1rem;
    border-radius: 5px;
    box-shadow: 1px 4px 15px rgb(125 147 178 / 32%);
    transition: all .3s;
    background: rgba(255, 255, 255, 0.7);
  }
  .content .mine_data:hover{
    box-shadow: 1px 4px 15px rgb(80 90 110 / 32%);
  }
  .content .mine_data .item{
    padding: 1rem 2rem ;
    font-size: 1.2rem;
    
  }
  .content .mine_data .color{
    color: #277840;
  }
  
  .content .item_wrapper{
    margin: 1rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: baseline;
    border-radius: 5px;
    box-shadow: 1px 4px 15px rgb(125 147 178 / 32%);
    transition: all .3s;
    background: rgba(255, 255, 255, 0.5);
  }
  .content .item_wrapper:hover{
    box-shadow: 1px 4px 15px rgb(80 90 110 / 32%);
  }
  .content .item_wrapper .item{
    width: 90vw;
    height: 25vw;
    border-radius: .5rem;
    background-color: rgba(255, 255, 255, 0.5);
    margin: 1rem 1rem;
    position: relative;
    /* box-shadow: 1px 4px 15px rgb(125 147 178 / 32%); */
    border: 1px solid rgb(235, 232, 232);
    box-sizing: border-box;
    transition: all .3s;
  }
  .content .item_wrapper .item a{
    width: 100%;
    height: 100%;
    background-color: rgb(231, 231, 231);
  }
  .content .item_wrapper .item:hover{
    box-shadow: 1px 4px 15px rgb(125 147 178 / 32%);
    cursor: pointer;
  }
  .content .item_wrapper .item .avatar{
    margin: 2vw 0 0 5vw;
  }
  .content .item_wrapper .item .avatar img{
    background-color: rgb(231, 231, 231);
    width: 10vw;
    height: 10vw;
    display: block;
    border-radius: 50%;
    box-sizing: border-box;
    border: 1px solid rgb(153, 151, 151);
  }
  .content .item_wrapper .item .name{
    /* background-color: pink; */
    font-size: 1.4rem;
    position: absolute;
    left: 20vw;
    top: 5vw;
    color: rgb(41, 107, 121);
  }
  .content .item_wrapper .item .desc{
    font-size: 1.2rem;
    position: absolute;
    top: 16vw;
    left: 5vw;
    color: rgb(67, 139, 153);
  }
}
.up{
  background-color: rgba(255, 255, 255, 0.5);
  
}
</style>